import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
import YouTube from 'react-youtube';
import {parse} from 'query-string';
import styled from 'styled-components';
import findIframeRatio from '../../../util/findIframeRatio';
import {merge} from 'lodash';

const FitVid = styled.div`
  height: 0;
  position: relative;
  background-color: ${props => props.ready ? 'transparent' : 'black'};

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`
  
// Responsive Youtube Video

const YoutubeVideo = ({
  settings,
  url,
  autoPlay
}) => {

  const [paddingTop, setPaddingTop] = useState(56.25);
  const [ready, setReady] = useState(false);

  const wrapperRef = useRef(null)

  const playVideo = (event) => {
    event.target.playVideo();
  }

  const getSize = () => {
    const ratio = findIframeRatio(wrapperRef.current);
    setPaddingTop(ratio);
  }

  const qs = url.split('?');
  const params = parse(qs[1]);

  const options = merge({
    opts: {
      playerVars: {
        start: 1
      }
    }
  }, settings)

  return (
    <FitVid style={{paddingTop: `${paddingTop}%`}} ready={ready} ref={wrapperRef}>
      <YouTube
        videoId={params.v}
        onReady={(e) => {
          autoPlay && playVideo(e);
          getSize(e);
          setReady(true);
        }}
        {...options}
      />
    </FitVid>
  )
}

YoutubeVideo.propTypes = {
  autoPlay: propTypes.bool,
  url: propTypes.string.isRequired
}

YoutubeVideo.defaultProps = {
  autoPlay: false,
}

export default YoutubeVideo