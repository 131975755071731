import React, {useEffect, useState, useRef} from 'react'
import propTypes from 'prop-types'
import { debounce } from 'lodash'
import inViewport from '../../../util/inViewport'

const LazyVideo = (props) => {
    const {
        children
    } = props

    const [loadVideo, setLoadVideo] = useState(false) // useState(placeholderSrc ? true : false);

    
    const listener = (e) => {
        const show = inViewport(videoRef.current, props.threshold);
        if (show === true) {
            setLoadVideo(true)
        }
    };

    useEffect(() => {
        // init();
        listener();
        const delay = 200;
        window.addEventListener('scroll', debounce(listener, delay));
        return () => {
          window.removeEventListener('scroll', listener);
        };
      }, [])

    const videoRef = useRef(null);

    return (
        <div ref={videoRef}>
            <video ref={props.innerRef} {...props.attributes}>
                {(loadVideo) && props.children}
            </video>
        </div>
    )
}
    
LazyVideo.propTypes = {

}
    
LazyVideo.defaultProps = {
    threshold: 400,
    attributes: {},
    innerRef: null
}
    
export default LazyVideo