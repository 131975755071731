import {theme} from '../../themes/default-theme';
import React from 'react';

const useWidth = (PassedComponent) => 

  class UseWidth extends React.Component {

    constructor(props) {
      super(props);
      this.updateDimensions = this.updateDimensions.bind(this);
      this.state = {
        width: 'lg'
      }
    }
  
    updateDimensions = () => {
      const keys = [...theme.breakpoints.keys].reverse();
      const width = keys.reduce((output, key) => {
        const matches = window.matchMedia(theme.breakpoints.only(key).replace('@media ', '')).matches;
        return !output && matches ? key : output;
      }, null) || 'xs'
      if (width !== this.state.width) {
        this.setState({
          width
        })
      }  
    };

    componentDidMount() {
      this.updateDimensions();
      process.browser && window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
      process.browser && window.removeEventListener('resize', this.updateDimensions);
    }

    toggle() {
      this.setState({
        toggleStatus: !this.state.toggleStatus
      })
    }

    render() {
      /* We deconstruct this.props so that we don't pass the   
      * initialToggleStatus down to the PassedComponent because this prop is    
      * only relevant to the HOC 
      */
      const { initialToggleStatus, ...rest } = this.props
      return (
        <PassedComponent
          {...rest}
          width={this.state.width}
        />
      )
    }
  }

const ShowOnWidth = ({
  children,
  show
}) => {
  if (show) {
    return children
  } else {
    return (
      <div style={{display: 'none'}}>{children}</div>
    )
  }
}

export {ShowOnWidth, useWidth as default}

