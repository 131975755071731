export default (parent) => {
  const iframes = parent.getElementsByTagName('iframe');
  const iframe = iframes && iframes[0]
  if (iframe) {
    const width = iframe.width;
    const height = iframe.height;
    return height / width * 100
  }
  return null
}

